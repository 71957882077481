<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import CalendarApiService from "@/services/calendarService";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "users",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      title: "Users",
      isLoading: false,
      items: [
        {
          text: "users",
          href: "/users",
        },
        {
          text: "",
          active: true,
        },
      ],
      users: [],
      userSearch: "",
      debounceTimer: null,
      currentPage: 1,
      pageSize: 30
    };
  },
  beforeCreate(){
  },  
  created (){
    this.isLoading = true;
    CalendarApiService.getUsers(null, this.currentPage, this.pageSize).then((response) => {
      this.isLoading = false;
      this.users = response.data.data;
    })
  },
  mounted() {

  },
  computed: {     

  },
  methods: {
    debounceSearch(){

      if(this.debounceTimer  != null){
        clearTimeout(this.debounceTimer);
      }

      this.debounceTimer = setTimeout(() => {
          this.isLoading = true;
          this.currentPage = 1;
          if(this.userSearch != "" && this.userSearch != null){
            CalendarApiService.getUsers(this.userSearch).then((response) => {
              this.isLoading = false;
              this.users = response.data.data;
            })
          }else{
            CalendarApiService.getUsers(this.userSearch, this.currentPage, this.pageSize).then((response) => {
              this.isLoading = false;
              this.users = response.data.data;
            })
          }

      }, 500)

    },
    editUser(user){
      this.$router.push({ name: 'editUser', params: { userId: user.id } });
    },
    loadMoreUser(){
      this.currentPage++;
      CalendarApiService.getUsers(this.userSearch, this.currentPage, this.pageSize).then((response) => {
        this.isLoading = false;
        this.users = response.data.data;
      })
    }
  }
};
</script>

<template>
  <Layout> 
    <PageHeader :title="$t('general.users')" />
    <p>{{$t('general.userTopTextInfo')}}</p>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">      
            <div class="table-responsive">
              <b-form-input 
                id="userSearch" 
                :placeholder="$t('general.searchUser')"
                v-model="userSearch"
                @input="debounceSearch"
                class="mb-3"
                style="max-width:250px;">
              </b-form-input>      
              <table class="table mb-0 table-hover" style="position:relative;">
                <loading :active.sync="isLoading" 
                  :can-cancel="false"
                  color='#f1b44c'
                  :width=70
                  :height=70
                  loader='dots'
                  :is-full-page="false">
                </loading>  
                <thead class="thead-light">
                  <tr>
                    <th>{{$t('general.name')}}</th>
                    <!-- <th style="text-align:center;">{{$t('general.email')}}</th> -->
                    <th style="text-align:center;">{{$t('general.roles')}}</th>
                    <th style="width:100px; text-align:center;">{{$t('general.edit')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in users" :key="index">
                    <td><a v-on:click.prevent="editUser(user)" href="#">{{user.displayName}}</a></td>
                    <!-- <td style="text-align:center;">{{user.userPrincipalName}}</td> -->
                    <td style="text-align:center;">{{user.roles}}</td>
                    <td style="text-align:center;">
                      <i @click="editUser(user)" style="cursor:pointer;" class="fas fa-pencil-alt text-success mr-1"></i>
                    </td>                      
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div style="display:flex; justify-content:center;">
              <a @click="loadMoreUser" href="#">{{$t('general.loadMore')}}</a>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
